.projects-grid{
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    gap: 3rem 3rem;
    
    background-color: white;
    max-width: clamp(280px,80%, 1300px);
    margin-right: auto;
    margin-left: auto;
    transition: all 0.3s ease-in-out;
}

.trabajos-previos{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding-block: 3rem 3rem;

}

.trabajos-previos .title{
    font-size: clamp(2.8rem, 6vw,3.3rem);
    text-align: center;
}

.project img{
    width: 100%;
    height: 100%;
    aspect-ratio: 1.1;
    object-fit: cover;

}

.project:hover{
    cursor: pointer;
    color: #c5050a;
}

.project{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 310px;
    transform: scale(0);
    animation: scaleUp 0.5s ease-in-out forwards;
    
    gap: 1rem;
    transition: 0.5s;
    /* width: clamp(300px, 50vw,400px); */
}

.project p{
    height: 50px;
    padding-inline: 1.5rem;
    text-align: center;
}

@media screen and (min-width: 600px) {
    .trabajos-previos{
        padding-inline: 2rem 2rem;
    }
}

@keyframes scaleUp {
	0% { opacity: 0; }
	100% { -webkit-transform: scale(1); transform: scale(1); opacity: 1; }
}