.microcement-title{
    font-family: 'Oswald', sans-serif;
    font-size: clamp(2.5rem, 5vw,3rem) !important; 
}

.example img{
    max-width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
}
.example{
    max-width: 25%;
}

.advantages-wrapper{

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

}

.advantages-section{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block: 3rem;
    gap: 3rem;
}

.advantages-section .main-title{
    font-size: clamp(2.3rem, 6vw,3rem);
}

.adv-title{
    font-size: 1.3rem;
    font-weight: 600 !important;
    
}

.advantages-section p{
    font-weight: 300;
}

.advantage{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    max-width: 80%;
    gap: 1rem;
    text-align: center;
}

.advantage img{
    height: 80px;
    width: 80px;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .advantage{
        max-width: 500px;
    }
}

@media screen and (min-width: 1200px) {
    .advantage{
        width: 100% !important;
    }

    .advantages-wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}