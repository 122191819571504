.delegacion .title{
    font-size: 20px;
    width: clamp(300px, 80%, 850px);
    font-size: clamp(1.5rem, 3vw,2.5rem);
}

.delegacion-escaparate{
    object-fit: contain;
    width: clamp(300px, 100%, 850px);
    border-radius: 2rem;
    margin-block: 2rem;
}

.text-align-justify{
    text-align: justify
}

.text-align-left{
    text-align: left;
}

.location-list{
    width: clamp(300px,70%, 800px);
}

.delegacion .sub-title, .delegacion .h3-title{
    width: clamp(300px,80%, 850px);
}

.h3-title{
    color: rgb(79, 79, 79);
}

@media screen and (max-width: 850px){
    .delegacion-escaparate{
        border-radius: 0;
    }
}