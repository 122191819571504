.contact-section{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding-block: 3rem;
}

.contact-section h1{
    font-size: clamp(2.5rem, 6vw,3.3rem);
    text-align:center;
}

.contact-form-div{
    display:flex;
    flex-direction: column;
    max-width: 85%;

}

.contact-form-wrapper{
    background-color: #353535;
}

.contact-info-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 3rem;
    background-color: #1e1e1e;
    color: white;
    padding: 3rem 2rem;
    font-size: 0.9rem;

}
.contact-info-wrapper p {
    margin: 0;
}

.contact-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:0.5rem;
}

.contact-item svg{
    color: #c5050a;
}

.contact-item a{
    color: white;
    transition: 0.25s;
}

.contact-item a:hover{
    color: #c5050a;
}

@media screen and (min-width: 1200px) {
    .contact-form-div{
        flex-direction: row;
        justify-content: center;
    }
}