.about-us{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding-block: 3rem;
    text-align: center;
}

.about-us p{
    margin: 0;
    max-width: clamp(300px,70%, 800px)
}

.about-us .title{
    font-size: clamp(2.8rem, 6vw,3.3rem);
    text-align:center;
}