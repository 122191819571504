@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&family=Poppins:wght@300;400;600;800&display=swap');

*{
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.btn-wrapper{
  width: fit-content;
}

body{
  margin: 0;
  padding: 0;
  background-color: #121212;
}

.btn{
  padding: 1em 1.5em;
  background-color: #c5050a;
  border: none;
  outline: none;
  color: white;
  text-transform: uppercase;
  border-radius: 1.5em;
  transition: all ease-in-out 250ms;
}

.embedded-a{
  text-decoration: none; /* Remove underlines */
  color: inherit; /* Inherit the color from the parent element */
}

.link{
  color: inherit;
  text-decoration: inherit;
  cursor: inherit;
}

ul{
  list-style: none;
  padding: 0;
}

a{
  text-decoration: none;
}

.title{
  font-family: 'Oswald', sans-serif;
}

.sub-title{
  font-family: 'Oswald', sans-serif;
  color: rgb(79, 79, 79);
}

.btn-text{
  font-size: 1rem;
  transition: all ease-in-out 250ms;
}

.btn-text:hover{
  color: #c5050a;
}


.btn:hover{
  background-color: white;
  color: #c5050a;
  cursor: pointer;
}

.btn-normal{
  font-size: 1rem;
}

.btn-large{
  font-size: 1rem;
  padding: 1.5em 2em;
}

.btn-big{
  font-size: clamp(1rem, 3vw, 1.2rem);
}

.btn-2{
  padding: 1.5em 1.8em;
  background-color: rgba(0,0,0,0);
  border: none;
  outline: none;
  color: white;
  text-transform: uppercase;
  border-radius: .5em;
  border: 2px solid #111111;
  color: black; 
  font-weight: 600;
  transition: all ease-in-out 250ms;
  font-size: .8rem;
  transition: all ease-in-out 250ms;
}

.btn-3{
  padding: 1em 1em;
  background-color: rgba(0,0,0,0);
  border: none;
  outline: none;
  color: white;
  text-transform: uppercase;
  color: black; 
  font-weight: 600;
  transition: all ease-in-out 250ms;
  font-size: .9rem;
  transition: all ease-in-out 250ms;
  transition: 0.25s ease-in-out;
}

.btn-3:hover{
  cursor: pointer;
}

.bold{
  font-weight: bold;
}

.btn-2:hover{
  transform: scale(1.1);
  cursor: pointer;
  text-decoration: underline;
}


/*##################################### SERVICES ##############################################*/
.services-dark-color{
  background-color: #c6c6c6 !important;
}

.services_section{
  display: flex;
  flex-direction: column;
  gap: 3rem;
  text-align: center;
  padding: 3rem 0;
  background-color:#e1e1e1;
  align-items: center;
}

.services_wrapper{
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.services_section .main-title{
  font-weight: 700;
  font-family: 'Oswald', sans-serif;
  font-size: clamp(2.5rem, 5vw,3.3rem);
  max-width: 80%;
}

/*##################################### GENERAL_INFO ##############################################*/
.general_info{
  background-color:#e1e1e1;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align:center;
  align-items: center;
}

.general_info .main-title,p{
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.general_info .main-title{
  font-size: clamp(1.7rem, 5vw,2.3rem);
  font-weight: 800;
}

.general_info p{
  text-align: center;
}

/*##################################### CUSTOMERS ##############################################*/
.customers{
  background-color: white;
  padding: 2rem 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.customers h2{
  text-transform: uppercase;
  font-weight: 800;
  font-family: 'Oswald', sans-serif;
  font-size: clamp(2.3rem, 6vw,3.3rem);
}

.logo-grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 1300px;
}

.logo-grid img{
  width: 100%;
  aspect-ratio: 1/1;
}

/*##################################### A NAME YOU CAN TRUST ##############################################*/
.name-trust{
  background-image:  url("../public/sample2.jpg");

  background-repeat: no-repeat;
  background-size: cover;
  padding: 3rem 2rem;
  
}


.inside-section{
  margin: 0;
  padding: 0;
  background-color: white;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
  
}

.check-icon{
  margin-right: 1rem;
  color: red;
}
.inside-section li{
  padding-block: 0.35rem;
}

.inside-section .main-title{
  font-weight: 800;
  font-family: 'Oswald', sans-serif;
  font-size: 3rem;
  line-height: 1.2;
}

@media screen and (min-width: 600px){
  .inside-section{
    text-align: left;
    padding: 5rem 4rem;
  }

  .inside-section .h_line{
    margin-left: 0 !important;
    margin-right: 50%;
  }

  .inside-section p{
    margin-left: 0;
  }
}

/*##################################### INSTAGRAM ##############################################*/

.insta-wrapper{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem 2.5rem;
}

.insta-section{
  background-color: white !important;
}

.insta-post{
  aspect-ratio: 1/1;
  border-radius: 5%;
  position: relative;

}

.insta-post img{
  width: 100%;
  height: 100%;
  max-width: 400px;
  aspect-ratio: 1/1;
  object-fit: cover;

}

.insta-slide-icon{
  position: absolute;
  right: 5%;
  top: 5%;
  color: white;

}


@media screen and (min-width: 1200px){
  .logo-grid{
    grid-template-columns: repeat(6, 1fr);
    width: 70vw;
  }

  .services_wrapper{
    flex-direction: row;
  }

  .general_info p,h2{
    max-width: 750px;
  }

  .insta-wrapper{
    display: flex !important;
    flex-direction: row !important;
  }

}


@media screen and (min-width: 769px){


  .insta-wrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    
  }

  .insta-wrapper img{
    max-width: 350px;
  }

}

@media screen and (max-width: 1200px) and (min-width: 769px){
  .topbar{
    flex-direction: column;
    gap: 1rem;
  }

  .topbar ul{
    padding: 0;
  }

  .logo-grid{
    grid-template-columns: repeat(4, 1fr);
    width: 80vw;
  }
}

@media screen and (max-width: 768px){
  nav li{
    display: block;
  }

  nav a::before{
    display: none;
  }

  .content-wrapper{
    transition: 0.5s;
    margin-top: -332px;
    position: relative;
    z-index: 1;
  }

  .move_down{
    margin-top: 0;
  }

  .burger-btn{
    visibility: visible;
  }

  .topbar{
    position: relative;
    flex-direction: column;
    padding-top: 1.5rem;
    padding-bottom: 0;
    z-index: 3;
  }

  .topbar ul{
    margin-top: 1.5rem;
  }

  .topbar img{
    max-width: 300px;
  }
  .topbar li{
    margin-bottom: 1rem;
    margin-inline: 1vw;
  }

  .topbar a{
    font-size: 1rem;
  }

  .topbar .btn-normal{
    font-size: 0.9rem;
  }

  .topbar ul{
    text-align: center;
    padding: 0;
  }

}