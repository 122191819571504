.quote-form{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
}

.form-wrapper .btn-wrapper{
    display: flex !important;
    justify-content: left !important;
}

.form-wrapper .btn{
    border-radius: 0 !important;
    font-size: 0.9rem !important;
}


.quote-form input, .quote-form textarea{
    padding: 1.5rem 1rem;
    border-radius: 0;
    transition: all ease-in-out 150ms;
    border: none;
}

.quote-form input:focus, .quote-form textarea:focus{
    outline: none;
    outline: 3px solid #c5050a;
}

.quote-form #description{
    height: 120px;
    resize: vertical;
}

.form-privacy{
    font-size: 0.8rem;
}

.form-wrapper{
    margin-top: 3rem;
    background-color: #111111;
    padding: 4rem 2rem;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.form-wrapper .title{
    font-family: 'Oswald', sans-serif;
    font-size: 2.3rem;
}

.error-msg{
    color: rgb(255, 238, 0);
    
}