/*##################################### TOPBAR ##############################################*/
.topbar{
    margin:0;
    background: #111111;
    padding-block: 1.5rem;
    padding-inline: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1600px;
 
}

.topbar-wrapper{
  width: 100%;
  background: #111111;
  display: flex;
  justify-content: center;
}
  
  /* .topbar::after{
    content: '';
    display: flex;
    align-items: center;
  
  } */
  
  .phone-icon{
    color:#c5050a;
  }
  
  .topbar ul{
    list-style: none;
  }
  
  .topbar a{
    text-decoration: none;
    color: white;
    font-size: 1.2rem ;
  }
  
  .topbar li{
    display: inline-block;
    margin: 0 clamp(1rem, 1.5vw, 2rem);
    
  }
  
  .burger-btn{
    color: white;
    background: none;
    border: none;
    visibility: hidden;
  }
  
  .burger-btn:hover{
    cursor: pointer;
  }
  
  /*##################################### NAVBAR ##############################################*/
  
  
  nav{
    text-align: center;
    color: #c5050a;
    background-color: #c6c6c6;
  }
  
  nav ul{
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .topbar img{
    width: 300px;
    height: 101px;
  }
  
  nav li{
    display: inline-block;
    align-items: center;
   
    font-size: 1rem;
    text-transform: uppercase;
    position: relative;
    padding: 1rem 0;
    font-weight: 300;
  }

  .microcement-nav:hover{
    cursor: pointer;
  }
  
  
  nav a, button{
    text-decoration: none;
    color: #111111;
    padding: 0 clamp(0.7rem, 1.5vw, 2rem);;
    border: none;
    background: none;
  }

  nav button{
    text-align: center;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 300;
  }
  .dropdown-parent{
    height: 100%;
    width: 100%;
  }
  .dropdown-hidden{
    flex-direction: column;
    position: absolute;
    top:45px;
    z-index: 9999;
    background-color: #c6c6c6;
    text-align: left;
    width: 200%;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
  }

  .dropdown-shown{
    transition: 0.5s;
    flex-direction: column;
    position: absolute;
    top:50px;
    z-index: 9999;
    background-color: #c6c6c6;
    text-align: left;
    width: 200%;
    display: flex;
    opacity: 1;
    
  }

  nav a:hover, nav button:hover{
    color: #c5050a;
  }

  
  
  nav a::before, nav button::before{
    content: '';
    display: block;
    height: 5px;
    width: 100%;
    background-color: #c5050a;
    position: absolute;
    top: 0;
    width: 0%;
    left: 0;
  
    transition: all ease-in-out 250ms;
    
  }

  
  nav a:hover::before, nav button:hover::before{
    width: 100%;
  }

  @media screen and (max-width: 1200px) and (min-width: 769px){
    .topbar{
      flex-direction: column;
      gap: 1rem;
    }
  
    .topbar ul{
      padding: 0;
    }
}

  @media screen and (max-width: 768px){
    nav li{
      display: block;
    }

    .dropdown-shown .dropdown-hidden{
        width: 100%;
        text-align: center;
    }

    .dropdown-item{
        width: 100vw;
        text-align: center;

    }

    .navbar{
        width: 100%;
    }
  
    nav a::before, nav button::before{
      display: none;
    }
    .topbar{
        position: relative;
        flex-direction: column;
        padding-top: 1.5rem;
        padding-bottom: 0;
        z-index: 3;
      }
    
      .topbar ul{
        margin-top: 1.5rem;
      }
    
      .topbar img{
        max-width: 300px;
      }
      .topbar li{
        margin-bottom: 1rem;
        margin-inline: 1vw;
      }
    
      .topbar a{
        font-size: 1rem;
      }
    
      .topbar .btn-normal{
        font-size: 0.9rem;
      }
    
      .topbar ul{
        text-align: center;
        padding: 0;
      }
      .burger-btn{
        visibility: visible;
      }

}