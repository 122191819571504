.types-micro{
    background-color: white;
    padding-block: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
}

.types-micro .section-title{
    font-size: clamp(2.3rem, 6vw,3rem);
    text-align: center;
}

.micro-tipo{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    text-align: center;
    max-width: 500px;
}

.micro-tipo img{
    width: 70%;
    object-fit: cover;
}

@media screen and (max-width: 1200px) {
    .types-micro{
        padding-block: 3rem;
    }

    .micro-tipo{
        max-width: clamp(300px, 50vw, 500px)
    }

    .micro-tipo img{
        width: clamp(300px, 50vw, 320px)
    }

    .section-title{
        max-width: 80%;
    }
}