/*##################################### FOOTER ##############################################*/
.footer{
    background-color: #111111;
    display:flex;
    gap: 2rem;
    flex-direction: column;
    text-align: center;
    align-items: center;
    color: white;
    font-size: 0.8rem;
  }

  .footer p{
    margin:0;
    width: 80%;
  }
  
  .footer img{
    margin-top: 2rem;
    width: 300px;
    height: 101px;
  }
  
  .social-media{
    font-size: 1rem;
    display: flex;
    gap: 2rem;
    padding: 0;
  }
  
  .footer li:hover{
    cursor: pointer;
  }
  
  .social-media ul{
    display: table;
  }
  
  .social-media li{
  
    aspect-ratio: 1 / 1;
    display: flex;
    justify-content: center;
  }
  
  .social-media a{
    -moz-box-sizing: border-box;
    padding: 0.5rem 0.5rem;
    display: flex; /* CSS3 */
    align-items: center; /* Vertical align */
    justify-content: center; /* Horizontal align */
    aspect-ratio: 1/1;
  
  }
  
  .brand-icon{
    aspect-ratio: 1/1;
    transition: 0.2s;
  }
  
  .brand-icon:hover{
    color: #c5050a;
  }
  
  .policies{
    display: flex;
    align-items: center;
    padding: 0;
    font-size: 0.8rem;
    margin-bottom: 2rem;
    gap: 1rem;
  }
  
  
  .footer a{
    color: white;
  }
