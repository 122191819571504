.small-carousel{
    width: 100%;
    
    aspect-ratio: 1/1;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    transition: 0.500s;
}

.small-carousel-wrapper{
    width: clamp(300px, 100%, 400px);
    aspect-ratio: 1/1;
}

.btn-carousel-small{
    border: none;
    background-color: rgba(0,0,0,0.65);
    color: white;
    padding: 1rem 1rem;
}

.btn-carousel-small:hover{
    cursor: pointer;
}

.small-carousel-text{
    margin-top: 1rem;
    font-size: 2rem;
}
