.tokens-list{
    display: flex;
    justify-content: center;
    list-style: none;
}

.tokens-list li{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    width: 30%;
}

.tokens-list li h3{
    margin: 0;
    padding: 0;
    height: 3.5rem;
    max-width: 80%;

}

.tokens-list li p{
    text-align: justify;
}

@media screen and (max-width: 1100px){
    .tokens-list{
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        padding-inline: 3rem;
    }

    .tokens-list li{
        width: 100%;
        
    }

    .tokens-list li h3{
        height: auto;
        text-align: center;
    }
}

.browsers-list{
    display: flex;
    justify-content: center;
    gap: 2rem;
    list-style: none;
    width: 100%;
    margin-block: 3rem;
}

.browsers-list li{
    width: 10vw;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.browsers-list li h3{
    margin: 0;
    padding: 0;
    height: 3rem;
    max-width: 80%;

}

.browsers-list li a{
    text-align: center;
    color: #c5050a;
}

.browsers-list li a:hover{
    cursor: pointer;
}

@media screen and (max-width: 1100px){
    .browsers-list{
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        padding-inline: 3rem;
    }

    .browsers-list li{
        width: 100%;
        
    }

    .browsers-list li h3{
        height: auto;
        text-align: left;
    }
}