

.images-grid {
    
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
    gap: 3rem 3rem;
    
    background-color: white;
 
    margin-right: auto;
    margin-left: auto;
    transition: all 0.3s ease-in-out;
}

.images-grid img{
    object-fit: cover;
    max-width: 100%;
    transform: scale(0);
    animation: scaleUp 0.5s ease-in-out forwards;
    
}


.project_page{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding-block: 3rem 3rem;
}

.project_page .title{
    font-size: clamp(2.8rem, 6vw,3.3rem);
    text-align: center;
}

.nav-buttons-div{
    display: flex;
    justify-content: center;
    gap: 3rem;
}

.move-left:hover{
    transform: translateX(-20px)
}
.move-right:hover{
    transform: translateX(20px)
}

@keyframes scaleUp {
	0% { opacity: 0; }
	100% { -webkit-transform: scale(1); transform: scale(1); opacity: 1; }
    
}