/* src/LanguageSelector.css */
.language-selector {
    position: relative;
    display: inline-block;
  }
  
  .language-icon {
    font-size: 24px;
    cursor: pointer;
  }
  
  .language-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 3;
    width: max-content;
  }
  
  .language-dropdown div {
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .language-dropdown div:hover {
    background-color: #f1f1f1;
  }
  
  .language-dropdown span {
    margin-right: 8px;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .language-dropdown {
      right: 0;
    }
  }