.image-grid{
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    gap: 10px;
    
    background-color: white;
    max-width: clamp(280px,80%, 1300px);
    margin-right: auto;
    margin-left: auto;
    transition: all 0.3s ease-in-out;
}

.img-item{
    max-width: 250px;
    aspect-ratio: 1.25/1;
    object-fit: cover;
    transition: 0.5s;
}

.img-item:hover {
    transform: scale(1.2);
}

.colors-section{
    background-color: white;
    padding-block: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;

}

.colors-section h1{
    font-size: clamp(2.8rem, 6vw,3.3rem);
    text-align:center;
}