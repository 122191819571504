@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&family=Poppins:wght@300;400;600;800&display=swap');

.slide{
    width: 100%;
    position: absolute;
    
}

.slide img{
    object-fit: cover;
}


.h2-show{
    opacity: 1;
    transition: 4s;
}

.btn-left{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
}

.btn-right{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}

.btn-carousel{
    border: none;
    background-color: rgba(0,0,0,0.65);
    color: white;
    padding: 1.5rem 1.5rem;

}

.btn-carousel:hover{
    cursor: pointer;
}

.carousel{
    position: relative;
    background-color: #e1e1e1;
    padding-block: 2rem;
    height: 550px;
}

/*##################################### STOREFRONT ##############################################*/
.storefront{
    
    position: relative;
    max-width: 1300px ;
    
    padding-block: 10rem;
    display: flex;
    height: 220px;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #111111;
    margin-left: auto;
    margin-right: auto;
    border-radius: 2rem;
    overflow: hidden;
  }

  .storefront-shown{
    opacity: 1;
    transition: opacity 1.5s;
  }

  .carousel-img{
    position:absolute;
    z-index: 1;
    left:0;
    right:0;
    width: 100%;
    top:0;
    height: 100%;
    object-fit: cover;
    border-radius: 2rem;
    overflow-x: hidden;
  }
  
  .storefront .carousel-title, .storefront .btn-carousel, .storefront .btn-wrapper{
    z-index: 3;
  }

  .storefront .btn-wrapper{
    position: absolute;
    bottom: 35%;
  }
  .storefront .carousel-title{
    position: absolute;
    bottom: 50%;
    text-align: center;
    padding-block: 2rem;
    background-color: rgba(0,0,0,0.65);
    text-transform: uppercase;
    margin-inline: 1rem;
    padding-inline: 0.5rem;
    color: white;
    font-weight: 800;
    font-size: clamp(2rem, 5vw,2.7rem);
    font-family: 'Oswald', sans-serif;
    z-index: 3;
    max-width: 80%;
  }

@media screen and (max-width: 1300px){
    .carousel{
        padding: 0;
    }
    .storefront, .carousel-img{
        border-radius: 0;
    }
}

@media screen and (max-width: 768px){
    .btn-carousel{
        display: none;
    }

    .storefront .h2{
        max-width: 100%;
    }
}