.cookie-popup{
    width: 100%;
    background-color: black;
    color: white;
    padding-block: 2rem;
}

/* Style for the cookies banner container */
.cookies-banner {
    position: fixed; /* Fixed position keeps it at the bottom regardless of scrolling */
    left: 0;
    bottom: 0;
    width: 100%; /* Full width */
    background-color: #000; /* Background color */
    color: #fff; /* Text color */
    padding: 10px 0; /* Padding for content */
    text-align: center; /* Center-align text */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding-block: 2rem;
    z-index: 100000; /* Ensure it's above other elements */
}

/* Style for the cookies banner content (you can customize this as needed) */
.cookies-banner p {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

.gray{
    background-color: rgb(90, 90, 90) !important;
}

.cookies-button-group{
    display: flex;
    gap: 5rem;
}

@media screen and (max-width: 500px){
    .cookies-button-group{
        
        flex-direction: column;
        gap: 1rem;
    }

    .cookies-banner p{
        max-width: 80vw;
    }
}



.cookies-banner a {
    color: #c5050a; /* Link color */
    text-decoration: underline;
}

.cookies-banner button {
    background-color: #c5050a; /* Button background color */
    color: #fff; /* Button text color */
    border: none;
    padding: 5px 15px;
    margin-left: 10px;
    cursor: pointer;
}
