.h_line{
    border:none;
    border-top: 2px solid brown;
    margin-inline: 2rem;
    min-width: 120px;

}

.serv-div{
    display:flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 .8rem;
    align-items: center;
    max-width: 400px;
}

.serv-div h2{
    font-size: 1.8rem;
    font-weight: 600;
}

.serv-div p{
    margin-inline: 1rem;
}

.serv-div img{
    
    width: clamp(300px, 100%, 400px);
    height: 245px;
}

@media screen and (min-width: 1200px) {
    .serv-div{
        width: 350px;
        height: 480px;
    }
    .serv-div img{
        width: 100%;
    }
}