/*##################################### START YOUR PROJECT ##############################################*/

.start-your-project{
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .project-text-wrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;

  
  }

  .project-text-section{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  
  }
  
  .project-text-section .title{
    font-family: 'Oswald', sans-serif;
    font-size: clamp(2.4rem, 6vw,2.8rem);
  }
  
  .project-text-wrapper p{
    margin: 0;
  }
  
  .star-icon{
    color:#c5050a;
  }
  
  .h_line_left{
      border:none;
      border-top: 2px solid brown;
      margin-right: 50%;
      min-width: 120px;
  }
  
  .project-point{
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  @media screen and (min-width: 900px) {
    .start-your-project{
      flex-direction: row;
      justify-content: center;
      padding-block: 2rem;
    }
    .project-text-wrapper{
      justify-content: center;
      margin-right: 1rem;
    }
  
  }

  @media screen and (min-width: 1200px){
    .inside-section{
        margin-left: 10rem;
        margin-block: 3.5rem;
        height: 50%;
        padding: 3rem 5rem;
        max-width: 30% !important;
        margin-left: 10% !important;
      }
  }

  @media screen and (min-width: 769px){
    .inside-section{
      max-width: 50%;
      margin-left: 5rem;
    }
}