.scroll-button{
    position: fixed; 
    
    right: 40px;
    bottom: 80px;
    font-size: 3rem;
    z-index: 9999;
    cursor: pointer;
    background-color: #353535;
    aspect-ratio: 1/1;
    width: 50px;
    height: 50px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    
    border-radius: 50%; /* Makes the button circular */

}
