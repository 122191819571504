.faq{
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block: 3rem 3rem;


}

.faq .title{
    font-size: clamp(2.3rem, 6vw,3rem);
    text-align: center;
    text-transform: uppercase;
    max-width: 85%;
    margin-bottom: 2rem;
}
