.question{
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
    padding: 1rem 2rem;
    border-radius: 1em;
    width: 80%;
    margin-block: 1rem 1rem;
    font-weight: 600;
    margin-left:auto;
    margin-right: auto;
}

.question:hover{
    cursor: pointer;
}

.answer{
    width: 80%;
    transition: 0.500s;
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    gap: 1rem;
}

.answer p{
    margin: 0;
    margin-left: 1rem;
    margin-right: 1rem;
    max-width: 100%;
    text-align: justify
}

.answer-hidden{
    max-height: 0;
    overflow: hidden;
}

.answer-shown{
    max-height: 750px;
}

.quest-answer-div{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 1200px) {
    .answer-shown{
        max-height: 1000px;
    }

}

@media screen and (max-width: 769px){
    .quest-answer-div{
        max-width: clamp(320px,70%, 500px);
    }

}