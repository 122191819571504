.contact-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 100%;
    padding-block: 2rem;
}

.form-wrapper .btn-wrapper{
    display: flex !important;
    justify-content: left !important;
}

.form-wrapper .btn{
    border-radius: 0 !important;
    font-size: 0.9rem !important;
}

.form-elements-wrapper{
    display: flex;
    flex-direction: column;

    width: 90%;
    
}

.form-elements-wrapper hr{
    
    border-color: #868686;
}

.form-elements-wrapper p{
    margin: 0;
}

.form-elements-wrapper input, .form-elements-wrapper textarea{
    margin-top: 2rem;
    padding: 1rem 1rem;
    border-radius: 0;
    transition: all ease-in-out 150ms;
    border: none;
    background-color: #353535;
    color: #aaaaaa;
}

.form-elements-wrapper #description{
    height: 80px;
    resize: vertical;
}

.form-elements-wrapper input:focus, .form-elements-wrapper textarea:focus{
    outline: none;

}

.form-elements-wrapper .form-privacy{
    margin-top: 2rem;
    color:rgb(207, 207, 207);
}

.form-elements-wrapper .form-privacy{
    font-size: 0.8rem;
}

.form-wrapper{
    margin-top: 3rem;

    padding: 4rem 2rem;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.form-wrapper h1{
    font-family: 'Oswald', sans-serif;
    font-size: 2.3rem;
}

.form-elements-wrapper .btn-wrapper{
    margin-top: 1rem;
}

.form-elements-wrapper .error-msg{
    color: #d4050c;
    margin-top: 0.5rem !important;
    
}